// Here you can add other styles
/** WARNING - USE AT OWN RISK */
/** IT IS AGAINST APPLE'S POLICY TO USE SF PRO FOR ANYTHING OTHER THAN iOS/tvOS/macOS/watchOS DESIGN & DEVELOPMENT */
/** https://sf.abarba.me/font.css */
/** https://sf.abarba.me/LICENSE.pdf */

/** 1. Copy/import this file into your main css/scss file */
/** 2. Change css font-family: to "SF Text", "SF Display" or "SF Mono" */
/** 3. Apply font-weight or italic to html elements */

/** THANK YOU */
/** I host these fonts on Cloudfront with SSL in all AWS regions for the best performance and reliability */
/** If you'd like to help offset costs, I'd very much appreciate a donation via Lisk https://lisk.io */
/** Address: 14987768355736502769L */
/** Delegate: andrew */

/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** Black */
@font-face {
  font-family: "SF Display";
  font-weight: 900;
  src: url("https://sf.abarba.me/SF-UI-Display-Black.otf");
}

/** Bold */
@font-face {
  font-family: "SF Display";
  font-weight: 700;
  src: url("https://sf.abarba.me/SF-UI-Display-Bold.otf");
}

/** Heavy */
@font-face {
  font-family: "SF Display";
  font-weight: 800;
  src: url("https://sf.abarba.me/SF-UI-Display-Heavy.otf");
}

/** Light */
@font-face {
  font-family: "SF Display";
  font-weight: 200;
  src: url("https://sf.abarba.me/SF-UI-Display-Light.otf");
}

/** Medium */
@font-face {
  font-family: "SF Display";
  font-weight: 500;
  src: url("https://sf.abarba.me/SF-UI-Display-Medium.otf");
}

/** Regular */
@font-face {
  font-family: "SF Display";
  font-weight: 400;
  src: url("https://sf.abarba.me/SF-UI-Display-Regular.otf");
}

/** Semibold */
@font-face {
  font-family: "SF Display";
  font-weight: 600;
  src: url("https://sf.abarba.me/SF-UI-Display-Semibold.otf");
}

/** Thin */
@font-face {
  font-family: "SF Display";
  font-weight: 300;
  src: url("https://sf.abarba.me/SF-UI-Display-Thin.otf");
}

/** Ultralight */
@font-face {
  font-family: "SF Display";
  font-weight: 100;
  src: url("https://sf.abarba.me/SF-UI-Display-Ultralight.otf");
}

/*---------------------------------------------------------------------------*
 * SF UI Text
 *---------------------------------------------------------------------------*/

/** Bold */
@font-face {
  font-family: "SF Text";
  font-weight: 700;
  src: url("https://sf.abarba.me/SF-UI-Text-Bold.otf");
}

/** Bold Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 700;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-BoldItalic.otf");
}

/** Heavy */
@font-face {
  font-family: "SF Text";
  font-weight: 800;
  src: url("https://sf.abarba.me/SF-UI-Text-Heavy.otf");
}

/** Heavy Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 800;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-HeavyItalic.otf");
}

/** Light */
@font-face {
  font-family: "SF Text";
  font-weight: 200;
  src: url("https://sf.abarba.me/SF-UI-Text-Light.otf");
}

/** Light Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 200;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-LightItalic.otf");
}

/** Medium */
@font-face {
  font-family: "SF Text";
  font-weight: 500;
  src: url("https://sf.abarba.me/SF-UI-Text-Medium.otf");
}

/** Medium Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 500;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-MediumItalic.otf");
}

/** Regular */
@font-face {
  font-family: "SF Text";
  font-weight: 400;
  src: url("https://sf.abarba.me/SF-UI-Text-Regular.otf");
}

/** Regular Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 400;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-RegularItalic.otf");
}

/** Semibold */
@font-face {
  font-family: "SF Text";
  font-weight: 600;
  src: url("https://sf.abarba.me/SF-UI-Text-Semibold.otf");
}

/** Semibold Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 600;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-SemiboldItalic.otf");
}

/** Ultrathin */
@font-face {
  font-family: "SF Text";
  font-weight: 100;
  src: url("https://sf.abarba.me/SF-UI-Text-Ultrathin.otf");
}

/** Ultrathin Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 100;
  font-style: italic;
  src: url("https://sf.abarba.me/SF-UI-Text-UltrathinItalic.otf");
}

/*---------------------------------------------------------------------------*
 * SF Mono
 *---------------------------------------------------------------------------*/

/** Bold */
@font-face {
  font-family: "SF Mono";
  font-weight: 700;
  src: url("https://sf.abarba.me/SFMono-Bold.otf");
}

/** Bold Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 700;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-BoldItalic.otf");
}

/** Heavy */
@font-face {
  font-family: "SF Mono";
  font-weight: 800;
  src: url("https://sf.abarba.me/SFMono-Heavy.otf");
}

/** Heavy Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 800;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-HeavyItalic.otf");
}

/** Light */
@font-face {
  font-family: "SF Mono";
  font-weight: 200;
  src: url("https://sf.abarba.me/SFMono-Light.otf");
}

/** Light Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 200;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-LightItalic.otf");
}

/** Medium */
@font-face {
  font-family: "SF Mono";
  font-weight: 500;
  src: url("https://sf.abarba.me/SFMono-Medium.otf");
}

/** Medium Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 500;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-MediumItalic.otf");
}

/** Regular */
@font-face {
  font-family: "SF Mono";
  font-weight: 400;
  src: url("https://sf.abarba.me/SFMono-Regular.otf");
}

/** Regular Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 400;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-RegularItalic.otf");
}

/** Semibold */
@font-face {
  font-family: "SF Mono";
  font-weight: 600;
  src: url("https://sf.abarba.me/SFMono-Semibold.otf");
}

/** Semibold Italic */
@font-face {
  font-family: "SF Mono";
  font-weight: 600;
  font-style: italic;
  src: url("https://sf.abarba.me/SFMono-SemiboldItalic.otf");
}
body {
  font-size: 16px;
  color: #181d27;
  line-height: 17px;
  background-color: #e5e5e5;
  font-family: -apple-system, "SF Text", sans-serif;
}

.button {
  padding: 0 12;
  border-radius: 8px;
  .text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
}

.login-card {
  max-width: 380px;
  max-height: 437px;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.small-title {
  font-size: 20px;
  color: #000000;
}

.left-nav {
  height: 100vh;
  // max-width: 240px;
  padding: 27px;
}

.fullscreen {
  height: 100vh;
}

.card {
  margin-bottom: 0;
}

.logout{
  position: fixed;
  bottom: 0px;
  min-width: 240px;
}

.small-size{
  font-size: 14px;
  text-decoration: none;
}

#signOut:focus {
  outline: 0 !important;
  border-color: transparent;
  box-shadow: none;
}

.dropdown-signout {
  min-width: 8rem;
}

.MuiCheckbox-root{
  color: #DEE5ED !important;
}

.MuiCheckbox-root:hover {
  background: transparent !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #5BBF8F !important;
}